<template>
  <div>
    <el-card
      body-style="position: relative"
      shadow="0"
    >
      <div class="device__author">
        Реле ({{device.author}})
      </div>
      <div class="row" style="align-items: center">
        <div class="col-4 col-lg-3 col-md-3 col-sm-4 p-0 ">
          <button
            class="device__btn"
            :class="{ on_shadow: onOff === 'on' }"
            :disabled="!onOff"
            @click.stop="toggleOnOff"
          >
<!--            <font-awesome-icon icon="plug" size="2x" />
     :class="{ main_blue_color: onOff === 'on' }"
-->
            <span

              class="icon-socket"
              style="font-size: 32px"
            ></span>
          </button>
        </div>
        <div class="col-8 col-lg-9 col-md-9 col-sm-8 text-left text-sm-left text-md-center">
          {{device.name}}
          <!--
            <a v-if="onOff">
              {{onOff === 'on' ? '(включена)' : '(выключена)'}}
            </a>
          -->
          <div v-if="pwr">
            {{pwr}} Вт
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  /**
   * Компонент для отображения устройства типа socket(розетка)
   */
  name: 'Socket',
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    onOff() {
      /**
       * Свойство вкл/выкл
       */
      if (this.device && this.device.possibilities) {
        return this.device.possibilities['on-off'];
      }
      return false;
    },
    pwr() {
      /**
       * Свойство потребляемой мощности в ваттах
       */
      if (this.device && this.device.possibilities) {
        return this.device.possibilities.pwr;
      }
      return false;
    },
  },
  methods: {
    toggleOnOff() {
      /**
       * Функция обработки изменения свойства вкл/выкл
       */
      if (this.device && this.device.id && this.device.possibilities['on-off']) {
        this.$store.dispatch('changePossibilityValue', {
          deviceId: this.device.id,
          possibilityKey: 'on-off',
          newValue: this.device.possibilities['on-off'] === 'on' ? 'off' : 'on',
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.el-card {
  background: #3c4b73;
  border: solid 1px #4d5878;
  border-radius: 10px;
  color: white;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0,0,0,0.19);

}
.on_shadow {
  box-shadow: 6px 6px 10px -1px rgba(51,255,55,0.2), -6px -6px 10px -1px rgba(51,255,0,0.4);
}
</style>
